.uploadInProgress {
  width: 100%;
}

.uploadInProgressIconAndLinearProgress {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.uploadInProgressLinearProgress {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.linearProgress {
  width: "100%";
  max-width: 400px;
  margin-bottom: 8px;
}

/* PageHeader.js */
.no-page-header {
  display: none;
}

.page-header-title-and-actions {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.page-header-title {
  display: flex;
  align-items: center;
}

.page-header-title-typography-workspace {
  overflow: hidden;
  max-width: 400px;
  white-space: nowrap;
  unicode-bidi: plaintext;
  text-overflow: ellipsis;
}

.page-header-title-typography {
  overflow: hidden;
  max-width: 400px;
  white-space: nowrap;
  unicode-bidi: plaintext;
  text-overflow: ellipsis;
}

:lang(en).page-header-title-typography {
  overflow: hidden;
  max-width: 340px;
  white-space: nowrap;
  unicode-bidi: plaintext;
  text-overflow: ellipsis;
  font-size: 28px !important;
}

.page-header-title-skeleton {
  width: 100px;
  height: 42px;
}

/* Breadcrumb.js */
.breadcrumb-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.breadcrumb-go-back-button {
  background: var(--primary-light-color) !important;
  color: var(--primary-main-color) !important;
  margin-inline-end: 2px !important;
}

.breadcrumb-go-back-button-disabled {
  color: var(--black-transparent-35) !important;
  margin-inline-start: 2px !important;
  background-color: var(--bg-disabled-button) !important;
}

.breadcrumb-document-name-skeleton {
  width: 100px;
  height: 30px;
  margin-inline: 2px !important;
}

.breadcrumb-slash {
  margin-right: 4px;
  color: var(--black-transparent-35) !important;
}

/* RetrainNotif.js */
.retrain-notif-success {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px 0 16px;
  background-color: var(--success-light-color);
  border-radius: 4px;
}

.retrain-notif-failed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px 0 16px;
  background-color: var(--error-light-color);
  border-radius: 4px;
}

.retrain-notif-icon-and-mesasge {
  display: flex;
  align-items: center;
}

.retrain-notif-icon {
  margin-inline-end: 8px;
}

.retrain-notif-success-icon {
  color: var(--success-main-color);
}

.retrain-notif-success-message {
  color: var(--success-dark-color);
}

.retrain-notif-failed-message {
  color: var(--error-dark-color);
}

.retrain-notif-close-icon {
  color: var(--black-transparent-35);
}

/* HeaderActions.js */
.upload-dropdown-document-icon {
  margin-bottom: 8px;
  border-radius: 4px 48px 4px 4px;
  border: 4px solid #dde6fb;
}

.upload-dropdown-document-icon-in-hover {
  border-color: "";
  margin-bottom: 8px;
  border-radius: 4px 48px 4px 4px;
  border: 4px solid #6ca4fc;
  transition: all 0.3s ease-in;
}

.header-actions-container {
  display: flex;
  align-items: flex-start;
}

/* SearchInput.js */
.search-input {
  margin-inline-end: 8px;
}

.search-input-iconButton {
  padding: 6px !important;
}

/* AddDocumentButton.js */
.add-document-botton-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.add-document-botton {
  height: 40px;
  font-size: 14px;
}

.menu-list-props-upload-dropdown {
  display: flex;
  padding: 0px !important;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  height: auto;
  width: 480px;
}

.paper-props-upload-dropdown {
  min-width: 848px !important;
  min-height: 245px !important;
  overflow: visible !important;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32)) !important;
  margin-top: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.add-document-botton-menu-opened::after {
  content: "" !important;
  display: block !important;
  position: absolute !important;
  bottom: -18px !important;
  left: 40% !important;
  width: 10px !important;
  height: 10px !important;
  background-color: var(--secondary-text-color) !important;
  transform: translateY(-50%) rotate(45deg) !important;
  z-index: 999999 !important;
}

:lang(en).add-document-botton::after {
  left: 55% !important;
}

:lang(ar).add-document-botton::after {
  left: 10% !important;
}

.menu-item-upload-dropdown {
  display: flex;
  flex-direction: column;
  padding: 32px !important;
  max-width: 240px;
  min-width: 240px;
  align-items: center;
}

.menu-item-upload-dropdown:hover {
  color: var(--primary-medium-color);
}

.menu-item-icon-type-upload-dropdown {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.menu-item-document-name-upload-dropdown {
  width: 100%;
  text-align: center;
  white-space: normal;
}

.menu-item-document-name-upload-dropdown-hover {
  width: 100%;
  text-align: center;
  white-space: normal;
  color: var(--primary-medium-color) !important;
}

.menu-error-msg-upload-dropdown {
  display: flex;
  align-items: center;
  padding: 16px;
  text-align: center;
}

.helper-arrow-add-document-button {
  margin-top: 24px;
}

:lang(ar).helper-arrow-add-document-button {
  margin-inline-end: 90px;
  transform: none;
}

:lang(en).helper-arrow-add-document-button {
  margin-inline-end: 120px;
  transform: scaleX(-1);
}

/* dialogs */
.dialog-paper {
  border-radius: 56px 4px 4px 4px !important;
}

:lang(en).dialog-paper {
  border-radius: 4px 56px 4px 4px !important;
}

.quota-alert-container {
  background-color: var(--warning-light-color);
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 8px 16px;
}

.quota-alert-icon-and-message {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px;
}

.quota-alert-icon {
  color: var(--warning-dark-color);
  margin-inline-end: 8px;
}

.dialog-content-root {
  padding: 24px 80px 56px !important;
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow-y: unset;
}

.dialog-content-file-icon {
  color: var(--primary-medium-color);
  width: 48px !important;
  height: 36px !important;
  margin-top: 16px;
}

.dialog-content-description {
  margin-top: 16px;
  width: 400px;
}

.dialog-content-typography-quota {
  text-align: right;
}

:lang(en).dialog-content-typography-quota {
  text-align: left;
}

.dialog-content-subscription-renewal {
  display: flex;
  text-align: start;
}

.dialog-actions {
  display: flex;
  justify-content: space-between !important;
  border-top: 1px solid #0000001f;
  padding: 16px 24px !important;
}

.dialog-bloc {
  display: flex;
  align-items: flex-start;
  border: 1px solid #dde7fa;
  border-radius: 4px;
  width: 100%;
  padding: 0px 16px 16px;
}

.retranscribe-dialog-content-icon {
  width: 48px !important;
  height: 40px !important;
  margin-top: 16px;
}

/* DocumentDetails.js */
.document-details-container {
  min-width: 300px;
  margin: 40px 80px;
}

.folder-details-app-sebr {
  margin-top: 32px !important;
}

.document-details-item {
  margin-top: 32px;
}

.document-details-sub-title {
  font-size: 14px !important;
  color: var(--black-transparent-35) !important;
}

.document-details-item-accuracy {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.document-details-item-accuracy-circular-progress {
  position: relative;
}

.document-details-item-accuracy-circular-progress-bottom {
  color: var(--bg-disabled-button) !important;
}

.document-details-item-accuracy-circular-progress-top {
  color: var(--success-main-color) !important;
  position: absolute !important;
  left: 0 !important;
}

.document-details-item-accuracy-percentage {
  font-size: 20px !important;
  color: var(--black-transparent-35) !important;
  margin-inline-start: 16px !important;
}

.document-details-item-accuracy-circular-progress-and-percentage {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.document-details-item-words-accuracy-circular-progress-top {
  color: var(--secondary-main-color);
  position: absolute;
  left: 0;
}

.document-details-total-done-pages-linearProgress {
  width: 100%;
  margin-top: 8px;
}

.document-details-item-quota {
  margin-top: 32px;
  border: 1px solid #0000001f;
  padding: 8px;
  border-radius: 4px;
}

.document-details-item-quota-linearProgress {
  width: 100%;
  background-color: var(--black-transparent-35);
}

:lang(en).transcribe-button {
  direction: ltr !important;
}

.document-details-transcribe-more-pages {
  margin-top: 8px;
}

.document-details-transcribe-more-pages-link {
  cursor: pointer;
}

/* RetrainButton.js */
.retrain-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
  margin: 0 8px;
}
.hidden-retrain-container {
  display: none;
}
.retrain-button-icon-and-label {
  display: flex;
  align-items: center;
  padding: 7px 16px;
  color: #fff;
  background-color: var(--bg-disabled-button);
  border-radius: 4px;
}

.retrain-button-icon-and-label {
  display: flex;
  align-items: center;
  padding: 7px 16px;
}

.retrain-button-icon-and-label-in-progress {
  display: flex;
  align-items: center;
  padding: 6px 16px;
  color: var(--secondary-text-color);
}

.retrain-button-icon {
  color: var(--secondary-text-color);
}

.retrain-button-icon-in-progress {
  color: var(--secondary-text-color);
}

.retrain-steps {
  display: flex;
  margin-top: 4px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.retrain-steps-check-circle-icon {
  color: var(--success-main-color);
  width: 16px;
  height: 16px;
}

.retrain-steps-decription {
  color: var(--black-transparent-35);
}

.export-file-menu {
  top: 40px !important;
}

.export-file-menu-item-icon {
  color: var(--primary-main-color);
}

.export-file-menu-item-icon-pdf {
  color: var(--error-main-color);
}

.export-menu-items ul {
  width: 208px;
}

/* CustomTooltip.js */
.custom-tooltip-container-markAsDone-action {
  position: absolute;
  top: 40px;
  left: -30px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: rotate(180deg) !important;
  z-index: 9999;
}

.custom-tooltip-container-uploadOnboarding-action {
  position: absolute;
  top: 85px;
  right: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
  transform: rotate(180deg) !important;
}

.custom-tooltip-container {
  position: absolute;
  top: -80px;
  right: -30px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
  transform: none;
}

.custom-tooltip-markAsDone-content {
  transform: rotate(180deg) !important;
  display: flex;
  background-color: var(--secondary-main-color);
  border-radius: 4px;
  padding: 8px 16px;
  border: 2px solid var(--secondary-text-color);
  box-shadow: 0px 0px 32px var(--secondary-main-color);
}

:lang(en).custom-tooltip-markAsDone-content {
  direction: ltr;
}

.custom-tooltip-uploadOnboarding-content {
  transform: rotate(180deg) !important;
  display: flex;
  background-color: var(--secondary-main-color);
  border-radius: 4px;
  padding-inline-start: 10px;
  border: 2px solid var(--secondary-text-color);
  box-shadow: 0px 0px 32px var(--secondary-main-color);
  align-items: center;
}

.custom-tooltip-content {
  transform: none;
  display: flex;
  background-color: var(--secondary-main-color);
  border-radius: 4px;
  padding: 8px 16px;
  border: 2px solid var(--secondary-text-color);
  box-shadow: 0px 0px 32px var(--secondary-main-color);
}
:lang(en).custom-tooltip-content {
  direction: ltr;
}

.custom-tooltip-content-videoTutorial {
  transform: none;
  display: flex;
  background-color: var(--secondary-main-color);
  border-radius: 4px;
  padding-inline-start: 8px;
  align-items: center;
  border: 2px solid var(--secondary-text-color);
  box-shadow: 0px 0px 32px var(--secondary-main-color);
}

.custom-tooltip-retrain-icon {
  color: var(--error-medium-color);
  margin-inline-end: 8px;
}

.custom-tooltip-markAsDone-icon {
  color: var(--error-medium-color);
  margin-inline-end: 8px;
  transform: rotate(180deg);
}

:lang(en).custom-tooltip-icon-and-description {
  direction: ltr;
}

.custom-tooltip-description-and-close-icon-button {
  display: flex;
  align-items: flex-start;
  max-width: 235px;
}

.custom-tooltip-description-and-close-icon-button-videoTutorial {
  display: flex;
  align-items: center;
}

.custom-tooltip-description-and-close-icon-button-uploadOnboarding {
  display: flex;
  align-items: center;
}

:lang(ar).custom-tooltip-icon-button {
  align-items: flex-start;
  transform: translate(-16px, -6px) !important;
}
:lang(en).custom-tooltip-icon-button {
  align-items: flex-start;
  transform: translate(10px, -6px) !important;
}

.custom-tooltip-icon-button-uploadOnboarding {
  align-items: center;
}

.custom-tooltip-icon {
  color: var(--secondary-text-color);
}

.custom-tooltip-description-and-close-icon-button-successfullRetrain {
  display: flex;
  align-items: flex-start;
  width: 280px;
}

.custom-tooltip-description-and-close-icon-button-successfullRetrain-outside {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 600px;
}

.custom-tooltip-icon-button-successfullRetrain {
  padding: 0px !important;
}

.custom-tooltip-triangle-retrain {
  width: 0px;
  height: 0px;
  border-width: 10px 10px 0 10px;
  border-color: var(--secondary-text-color) transparent transparent transparent;
  border-style: solid;
  z-index: 999;
}

.custom-tooltip-content-retrain {
  transform: none;
  display: flex;
  align-items: flex-start;
  background-color: var(--secondary-main-color);
  border-radius: 4px;
  padding: 8px 16px;
  padding-inline-start: 8px;
  border: 2px solid var(--secondary-text-color);
  box-shadow: 0px 0px 32px var(--secondary-main-color);
}

.custom-tooltip-retrain {
  position: absolute;
  display: flex;
  bottom: 48px;
  width: 600px;
  right: -188px;
  flex-direction: column;
  align-items: center;
  border-width: 10px 10px 0 10px;
  border-color: var(--secondary-text-color) transparent transparent transparent;
  z-index: 999;
}

.custom-tooltip-container-retrain-vertical {
  position: absolute;
  top: 40px;
  left: -220px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
  transform: rotate(180deg) !important;
}

.custom-tooltip-container-retrain-horizontal {
  position: absolute;
  top: 40px;
  left: -30px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
  transform: rotate(180deg) !important;
}

.custom-tooltip-retrain-content {
  transform: rotate(180deg) !important;
  display: flex;
  background-color: var(--secondary-main-color);
  border-radius: 4px;
  padding: 8px 16px;
  border: 2px solid var(--secondary-text-color);
  box-shadow: 0px 0px 32px var(--secondary-main-color);
}

:lang(en).custom-tooltip-retrain-content {
  direction: ltr;
}

.custom-tooltip-prediction-container {
  position: absolute;
  top: 204px;
  width: 800px;
  display: flex;
  z-index: 9999;
  flex-direction: column;
  align-items: center;
}

.custom-tooltip-prediction-content {
  display: flex;
  background-color: var(--secondary-main-color);
  border-radius: 4px;
  padding: 8px 16px;
  border: 2px solid var(--secondary-text-color);
  box-shadow: 0px 0px 32px var(--secondary-main-color);
}

:lang(en).custom-tooltip-prediction-content {
  direction: ltr;
}

.custom-tooltip-description-and-close-icon-button-prediciton {
  display: flex;
  align-items: flex-start;
  max-width: 600px;
}

.custom-tooltip-triangle-markAsDone {
  width: 0px;
  height: 0px;
  border-width: 10px 10px 0 10px;
  border-color: var(--secondary-text-color) transparent transparent transparent;
  border-style: solid;
  z-index: 999;
}

.custom-tooltip-container-keepCurrentText {
  position: absolute;
  top: 72px;
  left: 30%;
  z-index: 9999;
}

.custom-tooltip-keepCurrentText-content {
  display: flex;
  background-color: var(--secondary-main-color);
  border-radius: 4px;
  padding: 8px 16px;
  border: 2px solid var(--secondary-text-color);
  box-shadow: 0px 0px 32px var(--secondary-main-color);
}

:lang(en).custom-tooltip-keepCurrentText-content {
  direction: ltr;
}

.custom-tooltip-description-and-close-icon-button-keepCurrentText {
  display: flex;
  align-items: flex-start;
  max-width: 600px;
}

.custom-tooltip-container-historyOnboarding-action {
  position: absolute;
  top: 58px;
  right: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
  transform: rotate(180deg) !important;
}

:lang(en).custom-tooltip-container-historyOnboarding-action {
  direction: ltr;
}

.custom-tooltip-description-and-close-icon-button-historyOnboarding {
  display: flex;
  align-items: flex-start;
}

.custom-tooltip-description-and-close-icon-button-historyOnboarding-horizontal {
  display: flex;
  align-items: flex-start;
  width: 152px;
}

.custom-tooltip-container-historyOnboarding-action-horizontal {
  position: absolute;
  top: 60%;
  left: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
  transform: rotate(180deg) !important;
}

/* ErrorLoadingData.js */
.error-loading-data {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  justify-content: center;
}

.error-loading-data-avatar-single-page {
  background-color: var(--bg-default) !important;
  width: 120px !important;
  height: 120px !important;
}

.error-loading-data-avatar {
  background-color: var(--bg-paper) !important;
  width: 120px !important;
  height: 120px !important;
}

.error-loading-data-message {
  margin-top: 16px !important;
}

.error-loading-data-reload-button {
  margin-top: 16px !important;
}

/* Pagination.js */
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: inherit;
  /* margin-top: 16px; */
}

.pagination-container-editor {
  height: 44px;
  background-color: var(--secondary-dark-color);
  display: flex;
  align-items: center;
  padding: 8px 16px;
  justify-content: space-between;
}

.pagination-and-outlinedInput {
  display: flex;
  align-items: center;
}

.go-to-page-Typography-disabled {
  color: var(--secondary-main-color);
}

.go-to-page-Typography-editor-view {
  color: var(--secondary-text-color) !important;
}

.outlined-input-pagination {
  margin-inline-start: 8px;
}
/* :lang(en).outlined-input-pagination {
  margin-bottom: 10px;
} */

.input-props-pagination {
  width: 86px !important;
  padding: 8px !important;
}

.input-props-pagination-editor {
  width: 86px !important;
  padding: 8px !important;
  color: var(--secondary-text-color) !important;
}

.go-to-page-container {
  display: flex;
  align-items: center;
}

.mark-page-as-done-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mark-page-as-done-title-icon {
  color: var(--success-main-color);
}

.mark-page-as-done-content-description {
  margin-top: 32px;
}

.dialog-circular-progress {
  position: absolute;
}

.dialog-paper-leaving-page {
  border-radius: 56px 4px 4px 4px !important;
  max-width: 400px !important;
}

:lang(en).dialog-paper-leaving-page {
  border-radius: 4px 56px 4px 4px !important;
}

.dialog-content-root-leaving-page {
  padding: 24px 24px 56px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialog-actions-leaving-page {
  display: flex !important;
  justify-content: space-between !important;
  padding: 16px 24px !important;
}

.line-history-dialog-content {
  display: flex;
  justify-content: flex-start;
}

:lang(ar).line-history-dialog-content {
  justify-content: flex-end;
}

.line-history-dialog-content-lines {
  width: calc(100% - 250px);
}
:lang(en).line-history-dialog-content-lines {
  width: 100%;
}

.get-history-by-date-circular-progress {
  position: absolute;
  top: 40%;
  right: 58%;
}

.lineHistory p {
  padding-right: 10px;
  line-height: 2.3;
  outline: 0px solid transparent;
  font-family: Readex Regular;
  font-size: 22px;
  margin-top: 0;
}

.lineHistory {
  list-style-type: none;
  counter-increment: step-counter;
}

.lineHistory :before {
  content: counter(step-counter) "  ";
  color: var(--black-transparent-35);
  font-size: 22px;
  margin-left: 12px;
}

.line-history-dialog-drawer-back-and-restore-version-actions {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  background-color: #eee;
  flex-wrap: wrap-reverse;
}

.line-history-dialog-drawer-back-icon {
  padding-inline: 8px;
}

:lang(ar).line-history-dialog-drawer-back-icon {
  padding-inline: 8px;
  transform: rotate(180deg);
}

.line-history-dialog-drawer-back-button {
  margin: 8px 16px 24px !important;
}

.line-history-dialog-drawer-restore-version-button {
  margin: 8px 16px !important;
}

.line-history-dialog-drawer-list-item {
  padding: 0 !important;
}

.line-history-dialog-drawer-list-updatedAt-item {
  padding: 8px;
  min-height: 60px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.line-history-dialog-drawer-list-updatedAt-item span {
  font-family: Readex Regular;
  font-size: 18px;
}

.line-history-dialog-drawer-list-line-item {
  color: var(--black-transparent-35);
  text-align: end;
}

:lang(ar).line-history-dialog-drawer-list-line-item {
  text-align: justify;
}

.onboarding-dialog-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  float: left;
}

.onboarding-dialog-svg-icon {
  color: var(--primary-medium-color);
  font-size: 32px !important;
}

:lang(en).onboarding-dialog-close-icon-button {
  position: absolute;
  top: 24px;
  right: 24px;
  transform: scale(1.3);
}

:lang(ar).onboarding-dialog-close-icon-button {
  position: absolute;
  top: 24px;
  left: 24px;
  transform: scale(1.3);
}

.dialog-actions-okIUnderstand-button {
  margin: 24px 0 0 !important;
}

.dialog-actions-uploadOnboarding {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 32px !important;
}

.onboarding-dialog-content-root {
  padding: 24px !important;
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 32px !important;
}

.custom-alert-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0 8px 0;
  margin: 8px 0px;
}

.custom-alert-container-reorder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0px 8px;
  margin: 0 8px 8px;
}

:lang(en).custom-alert-container-reorder {
  direction: ltr;
}

:lang(ar).custom-alert-container-reorder {
  direction: rtl;
}

:lang(en).custom-alert-container {
  direction: ltr;
}

:lang(ar).custom-alert-container {
  direction: rtl;
}

.custom-alert-container-customType {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-radius: 4px;
  padding: 8px 16px 16px 0;
  margin: 8px 0px;
}

:lang(en).custom-alert-container-customType {
  direction: ltr !important;
  padding: 8px 0px 16px 8px !important;
}

:lang(ar).custom-alert-container-customType {
  direction: rtl !important;
}

.custom-alert-icon-and-msg {
  display: flex;
  align-items: center;
}

.custom-alert-icon-and-msg-customType {
  display: flex;
  align-items: flex-start;
}

.custom-alert-msg {
  margin-inline-start: 4px;
}

.no-results-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 290px);
}

.no-results-avatar {
  background-color: var(--bg-paper) !important;
  width: 120px !important;
  height: 120px !important;
}

.no-results-description {
  margin-top: 16px !important;
}

.slash-screen-linear-progress {
  width: 400px !important;
  margin-top: 40px !important;
  background-color: var(--black-transparent-35);
}

.upload-zone-select-file-button {
  margin: 10px 0 !important;
}

.upload-zone-before-select-file {
  border: 1px dashed rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 16px 0 26px;
}

.upload-zone-after-select-file {
  border: 1px dashed rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 16px 0 16px;
}

.upload-zone-after-select-file-details-container {
  display: flex;
  padding: 16px;
  width: 100%;
}

.upload-zone-after-select-file-icon {
  margin-inline-end: 10px;
  color: var(--primary-medium-color) !important;
}

.upload-zone-after-select-file-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.upload-zone-after-select-file-details-failed-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(100% - 80px);
}

.upload-zone-after-select-file-name {
  unicode-bidi: plaintext;
  white-space: nowrap;
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700 !important;
  display: flex;
}

.upload-zone-after-select-file-name:hover {
  cursor: pointer;
}

.upload-zone-after-select-file-size-info {
  color: var(--error-dark-color) !important;
}

.upload-zone-after-select-file-conditions {
  margin: 10px 0 10px !important;
}

.upload-dialog-title {
  color: var(--secondary-main-color) !important;
}

.upload-dialog-content-one-step {
  margin-bottom: 56px;
  transform: translate(-0.1rem, -3ex);
}

.upload-dialog-content-one-step-document-calc-num-pages {
  display: none;
}

.upload-dialog-content-step-1-formControl {
  min-width: 100% !important;
}

.upload-dialog-content-step-2-load-data {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-dialog-content-step-2-error-loading-data {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 16px;
}

.upload-dialog-content-step-3 {
  min-width: 410px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-dialog-content-step-3-icon {
  width: 32px !important;
  height: 32px !important;
  color: var(--info-main-color);
}

.upload-dialog-content-step-3-checked-icon {
  width: 32px !important;
  height: 32px !important;
}

.upload-dialog-content-step-3-label {
  margin-inline-end: 8px;
}

.upload-dialog-content-step-3-label-checked {
  margin-inline-end: 8px;
}

.upload-dialog-content-step-3-face {
  border: 1px solid #0000001f;
  border-radius: 4px;
  width: 170px !important;
  padding: 10px 0 10px 20px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 16px;
}

.upload-dialog-content-step-3-face:hover {
  background-color: var(--bg-dark);
}

.upload-dialog-content-step-3-face-checked {
  border: 1px solid var(--primary-main-color);
  border-radius: 4px;
  margin-top: 16px;
  width: 170px !important;
  padding: 10px 0 10px 20px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.upload-dialog-content-step-3-face-checked:hover {
  background-color: var(--bg-dark);
}

.upload-dialog-content-step-4 {
  margin-bottom: 56px;
}

.upload-dialog-content-step-4-textFieldFrom-and-textFieldTo {
  display: flex;
  width: 100%;
}

.upload-dialog-content-step-4-textField {
  margin-left: 16px !important;
  width: 120px;
  height: 56px;
}

.upload-dialog-content-step-5-textField {
  margin-left: 16px !important;
  width: 329px;
  height: 56px;
}

.upload-dialog-content-step-5-textField-and-button {
  display: flex;
  width: 100%;
}

.loaded-video-metadata-zone {
  display: flex;
  align-items: center;
}

.loading-video-metadata {
  display: flex;
  align-items: center;
}

.loaded-video-thumbnail-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaded-video-thumbnail {
  border-radius: 4px;
  width: 80px;
  height: 80px;
}

.loading-video-metadata-title {
  unicode-bidi: plaintext;
  white-space: nowrap;
  max-width: 244px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold !important;
  font-size: 18px;
  text-overflow: ellipsis;
}

.loaded-video-description {
  padding-inline-start: 18px;
}

.upload-dialog-content-step-5 {
  transform: translate(-0.1rem, -3ex);
}

.upload-dialog-content-step-5-textFieldName {
  width: 100%;
}

.upload-dialog-title-container {
  background-color: var(--primary-light-color);
  position: relative;
}

.upload-dialog-title-title-and-close {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 460px;
  margin: auto;
}

.upload-dialog-title-close-icon {
  width: 30px;
  height: 30px;
}

.upload-dialog-dialogContent {
  display: flex;
  justify-content: center;
  width: 460px;
  padding-top: 56px;
  padding-bottom: 80px;
  margin: auto;
}

.upload-dialog-dialogContent-stepper {
  padding: 0 !important;
  width: 100%;
}

.upload-dialog-dialogContent-stepLabel {
  text-align: inherit !important;
}

.upload-dialog-dialogContent-stepContent {
  padding-bottom: 40px;
  border-right: none !important;
}

:lang(en).upload-dialog-dialogContent-stepContent {
  border-right-color: var(--bg-default) !important;
  border-inline-start: 1px solid var(--bg-default) !important;
  border-right: none !important;
}

:lang(en).upload-dialog-dialogContent-stepContent-lastStep {
  border-right-color: var(--bg-default) !important;
  border-right: none !important;
}

.upload-dialog-dialogActions {
  border-top: 1px solid #0000001f;
  padding: 16px 24px;
}

.upload-dialog-dialogActions-cancel-and-send-buttons {
  display: flex;
  justify-content: space-between;
  width: 460px;
  margin: auto;
}

.custom-upload-alert-icon {
  width: 24px;
  height: 24px;
  margin-inline-end: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.custom-upload-alert-icon-openBookIcon {
  width: 24px;
  height: 24px;
  margin-inline-end: 8px;
  margin-top: 4px;
}

.react-player {
  margin: 24px 0 !important;
}

.react-player-from-alert {
  margin: 0 0 24px !important;
}

.export-file-button-label {
  margin: 0 4px !important;
}

.export-file-button-label-document-view {
  margin: 0 4px !important;
}

/* add tutorial video onbording */
.how-to-use-zinki-button {
  padding: 8px !important;
  background: var(--white-transparent-color) !important;
}

.app-tutorial-video-dialog-paper {
  background-color: var(--secondary-main-color) !important;
  border-radius: 56px 4px 4px 4px !important;
  height: 450px !important;
}

.app-tutorial-video-dialog-paper-first-render {
  background-color: var(--secondary-main-color) !important;
  border-radius: 56px 4px 4px 4px !important;
  vertical-align: bottom !important;
  right: 30% !important;
}

:lang(en).app-tutorial-video-dialog-paper-first-render {
  border-radius: 4px 56px 4px 4px !important;
  left: 30% !important;
}

:lang(en).app-tutorial-video-dialog-paper {
  border-radius: 4px 56px 4px 4px !important;
}

.app-tutorial-video-dialog-title {
  padding: 24px !important;
}

.app-tutorial-video-dialog-title-typography {
  width: 260px;
}

:lang(en).app-tutorial-video-dialog-close-icon-button {
  position: absolute;
  top: 16px;
  right: 16px;
  transform: scale(1.1);
  color: var(--white-transparent-color);
}

:lang(ar).app-tutorial-video-dialog-close-icon-button {
  position: absolute;
  top: 16px;
  left: 16px;
  transform: scale(1.1);
  color: var(--white-transparent-color);
}

:lang(ar).app-tutorial-video-dialog-title-typography {
  margin-inline-end: 20px !important;
}

:lang(en).app-tutorial-video-dialog-title-typography {
  margin-inline-end: 40px !important;
}

.app-tutorial-video-dialogreact-player {
  margin: 0 0 10px 0 !important;
}

.how-to-use-zinki-container {
  position: relative;
}

.custom-tooltip-videoTutorial {
  position: absolute;
  bottom: 40px;
  left: -60%;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-pdf__Document {
  display: none;
}

.transcription-dialog-content-formControl {
  min-width: 100% !important;
}

.transcription-dialog-content-load-data {
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-model-drop-down {
  display: flex;
  align-items: flex-start;
  padding-bottom: 56px;
  width: 100%;
}

@media only screen and (max-width: 1420px) {
  .export-file-button-label {
    display: none;
  }

  .export-file-button-end-icon {
    display: none !important;
  }

  .custom-tooltip-container-markAsDone-action {
    left: -80px;
  }
}

@media only screen and (max-width: 1170px) {
  .go-to-page-container {
    display: none;
  }
}
