.header-appBar {
  background-color: var(--secondary-text-color) !important;
  z-index: 999;
  container: header-appBar / inline-size;
  width: 100%;
}
.header-appBar-portal {
  background-color: var(--secondary-text-color) !important;
  z-index: 999;
  display: none !important;
}
.header-toolbar {
  min-height: 64px;
  justify-content: space-between;
}
.header-logo-button {
  display: flex !important;
  align-items: center !important;
}
.header-logo-img {
  margin: 0 8px;
}

.header-profile-free-type {
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  background-color: var(--success-main-color);
}
.header-profile-premium-type {
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  background-color: var(--warning-main-color);
  margin-inline-start: 8px !important;
}
.header-profile-type-typography {
  color: var(--secondary-text-color) !important;
  font-weight: 600 !important;
}
.header-logout-button {
  margin-inline-start: 100px !important;
}
.header-upgrade-alert {
  padding: 8px 16px;
  display: flex;
  background-color: var(--info-light-color);
  z-index: 3;
}
.header-upgrade-alert-typography {
  margin-inline-end: 4px !important;
}
.header-upgrade-alert-link {
  cursor: pointer;
}

/* Header Actions (buttons)   */
.header-buttons {
  display: flex;
  justify-content: end;
  container: header-buttons / inline-size;
  width: 100%;
}
.button-go-to-sebr-search {
  margin-inline-end: 32px !important;
}
.button-workspace {
  margin-inline-end: 32px !important;
}
.select-language-button {
  margin-inline-end: 32px !important;
}

.menuItem-profile-item-icon {
  margin-inline-end: 16px;
}

.profile-button-email-label {
  color: var(--main-text-color);
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
  font-size: 16px !important;
}

@container header-appBar (width < 25em) {
  .header-logo {
    display: none;
  }
  .header-buttons {
    display: inline;
  }
}
@container header-buttons (width < 32em) {
  .button-workspace-label {
    display: none;
  }
  .button-go-to-sebr-search-label {
    display: none;
  }
  .button-go-to-sebr-search {
    margin-inline-end: 0 !important;
  }
  .button-workspace {
    margin-inline-end: 0 !important;
  }
}

@container header-buttons (width < 50em) {
  .profile-button-email-label {
    display: none;
  }
  .select-language-button-label {
    display: none;
  }
  .select-language-button {
    margin-inline-end: 0 !important;
  }
}
